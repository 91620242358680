import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import QRCode from 'react-qr-code';

const PdfReader = (props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isZoomed, setIsZoomed] = useState(false);

    // const qrBtnStyle = {
    //     zIndex: 4,
    //     right: 0,
    //     bottom: 0,
    //     position: "absolute",
    //     margin: 5
    // }

    return (
        <div className="iframe-box">
            {/*<iframe src={"/lib/web/viewer.html?file=" + encodeURIComponent(props.url) + "&embeded='true'&annotation=1"} width={"100%"} height={"100%"}></iframe>*/}
            <iframe src={"/lib/web/viewer.html?file=" + encodeURIComponent(props.url) + "&embeded='true'"} width={"100%"} height={"100%"} sandbox="allow-same-origin allow-scripts allow-forms allow-modals" title="PDF" style={{position: "absolute", top: 0, left: 0}}
                   ></iframe>
            {/*<iframe src={"https://iframe.multiapp.fr/lib/web/viewer.html?file=" + encodeURIComponent(props.url) + "&embeded='true'"} width={"100%"} height={"100%"} sandbox="allow-same-origin allow-scripts allow-forms allow-modals"></iframe>*/}

            <>
                {props.downloadable &&
                // <Button variant="dark" onClick={handleShow} style={qrBtnStyle}>
                //     <FontAwesomeIcon icon={faQrcode} fontSize='36px' color="white"></FontAwesomeIcon>
                // </Button>}

                <div className="mybutton-QR btnPMR" style={{zIndex: 4}}>
                    <a className="feedback" style={{
                        backgroundColor: "#343434",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }} onClick={handleShow}>
                        <QRCode value={props.url} size={50}></QRCode>
                    </a>
                </div>
                }

                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title className="centerModalTitle">Scannez pour télécharger</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="qr-container">
                            {/*{console.log("Pdfffffff link", props.url)}*/}
                            <QRCode value={props.url}></QRCode>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        </div>
    );
}

export default PdfReader;
